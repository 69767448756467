<template>
  <div class="content profile-content">
    <ProfileNavbarTeacher :title="$t('profile.title')"></ProfileNavbarTeacher>
    <div class="clearfix">
      <div class="row">
        <div class="col-md-12">
          <Loader v-if="loader"/>
          <div class="profile-edit-form w-100 table_winners" v-if="!loader">
            <button v-on:click="showButtons(false, false, false, true, false)" v-show="visibleBtnBack" class="back-btn">
              <img src="/images/arrow-back.png" alt="">
            </button>
            <div class="column">
              <div class="col-lg-4 col-md-12 education-info table_winners_title table_winners_block">
                <div class="table_winners_name">{{ $t('my_olympiad_winners') }}</div>
<!--                <div v-if="!showWinners2022">{{ $t('olympiad_no_winners') }}</div>-->
              </div>
              <div class="col-lg-4 col-md-12 education-info table_winners_block">
                <div class="col-12 order-lg-1 order-2 table_winners_response">
                  <div class="table-response-helper">
                    <div class="table-response">
                      <table class="table table-striped mb-0" id="firstResult">
                        <thead>
                        <tr class="table_winners_label">
                          <th>{{ $t('for-winners.table-name') }}</th>
                          <th>{{ $t('for-winners.table-school') }}</th>
                          <th>{{ $t('for-winners.table-year') }}</th>
                          <th>{{ $t('diploma') }}</th>

                          <!--                          <th>{{ $t('for-winners.table-name') }}</th>-->
                          <!--                          <th>{{ $t('for-winners.table-area') }}</th>-->
                          <!--                          <th>{{ $t('for-winners.table-district') }}</th>-->
                          <!--                          <th>{{ $t('for-winners.table-city') }}</th>-->
                          <!--                          <th> {{ $t('for-winners.table-school') }}</th>-->
                          <!--                          <th>{{ $t('for-winners.table-class') }}</th>-->
                          <!--                          <th>{{ $t('references.test-language-label') }}</th>-->
                          <!--                          <th>{{ $t('for-winners.table-total') }}</th>-->
                        </tr>
                        </thead>
                        <tbody v-if="winners2022.length > 0" >
                        <tr v-for="(winner, index) in winners2022" :key="winner.user.last_name + index"
                            class="table_winners_row">
                          <td>{{ winner.user.last_name }} {{ winner.user.first_name }} {{ winner.user.patronymic }}</td>
                          <td>{{ winner.user.school }}</td>
                          <td>{{ winner.user.education.class }}</td>
                          <td>
                            <a :href="winner.diploma ? backUrl + winner.diploma : '#'" :target="winner.diploma ? '_blank' : '_self'"
                               class="table_winners_btn">{{ $t('show_diploma') }}</a>
                          </td>

                        </tr>
                        </tbody>
                        <tbody v-else>
                        <tr>
                          <p class="emptyTable">{{ $t('nothing_found_for_your_request') }}</p>
                        </tr>
                        </tbody>
                      </table>
                      <!-- чтобы не было логов в консоли -->
                      <table id="secondResult"></table>
                      <table id="winnerResult"></table>
                      <!-- -->
                    </div>
                  </div>

                </div>

              </div>

            </div>
            <!--              <div class="row my-4 ">-->
            <!--                <button type="submit" class="btn btn-primary mx-auto col-lg-2 col-10">{{-->
            <!--                    $t('profile.save-btn')-->
            <!--                  }}-->
            <!--                </button>-->

            <!--              </div>-->
            <div v-show="visibleBtns" class="more-info-btns p-0">
              <a v-on:click="showInputs(true, false, false, false, true)" class="btn more-info-btn mt-5" href="#"
                 role="button">{{ $t('profile.main') }}</a>
              <a v-on:click="showInputs(false, true, false, false, true)" class="btn more-info-btn mt-5" href="#"
                 role="button">{{ $t('profile.education') }}</a>
              <a v-on:click="showInputs(false, false, true, false, true)" class="btn more-info-btn mt-5" href="#"
                 role="button">{{ $t('profile.representative') }}</a>
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>
<script>
import Vue from 'vue';
import {mapActions} from "vuex";
import ProfileNavbarTeacher from "@/components/ProfileNavbarTeacher";
import VueMask from 'v-mask'
import Loader from '@/components/Loader'
import * as API from "@/api";

export default {
  name: 'TeacherOlympiadWinners',
  beforeCreate() {
    Vue.use(VueMask);
  },
  components: {
    ProfileNavbarTeacher,
    Loader
  },
  data() {
    return {
      pickerOptions: {
        disabledDate(time) {
          let startDate = new Date();
          startDate.setMonth(11, 31);
          startDate.setFullYear(startDate.getFullYear() - 15);

          let endDate = new Date();
          endDate.setMonth(11, 31);
          endDate.setFullYear(endDate.getFullYear() - 9);

          return time.getTime() < startDate || time.getTime() >= endDate;
        }
      },
      loading: false,
      visibleMain: true,
      visibleEducation: true,
      visibleParent: true,
      userCanGo: false,
      acceptPhoto: false,
      takedScreenshot: false,
      takedImageSource: '',
      visibleBtns: false,
      visibleBtnBack: false,
      apiRoot: window.API_ROOT,
      avatarUrl: '',
      winners2021: [],
      winners2022: [],
      showWinners2021: false,
      showWinners2022: false,
      account: {
        iin: '',
        sex: '',
        first_name: '',
        last_name: '',
        patronymic: '',
        email: '',
        region_name: '',
        region_id: '',
        city_name: '',
        city_id: '',
        locality_name: '',
        locality_id: '',
        birth_date: '',
        phone: '',
        school_id: '',
        teaching_language_id: '',
        subject_id: '',
      },
      informedCheckbox: false,
      errorModalTitle: '',
      errorModalDesc: '',
      loader: false,
      backUrl: window.API_ROOT + '/storage/'
    }
  },
  methods: {
    // TODO доработать после того как будет АПИ


    setLocality() {
      this.$http.get(`${API.Api}/api/references/schools/${this.account.locality_id}`)
          .then(res => {
            this.disabledSchools = false,
                this.schools = res.body.data
            this.account.school_id = ''
          })
    },

    logoutMethod() {
      this.logout();
      localStorage.setItem('token', '');
      localStorage.setItem('user', '');
      sessionStorage.setItem('avatar-url', '')
      window.location = '/';
      // this.$router.push('/');
    },
    async confirmInformedModal() {
      try {
        await this.$http.post(`${window.API_ROOT}/api/user/informed`, {
          informed: 1
        })
        this.$bvModal.hide('announcement-modal')
      } catch (error) {
        Vue.toastr({
          message: this.$t('error'),
          description: error,
          type: 'error'
        })
      }
    },
    ...mapActions({
      'logout': 'logout'
    }),
  },
  mounted() {

    this.loader = true
    this.$http.get(window.API_ROOT + '/api/teacher/winners')
        .then((res) => {
          // this.winners2021 = res.body[2021]

          if (!res.body.length) {
            this.winners2022 = res.body
            this.loader = false
          } else {
            this.winners2022 = res.body[2022]
            this.loader = false
          }
        })
        .catch((e) => {
      console.log(e)
          this.loader = false
    });



    if (localStorage.getItem('user')) {

      let teacher = JSON.parse(localStorage.getItem('user'))

      this.account.iin = teacher.iin
      this.account.sex = +(teacher.gender)
      this.account.first_name = teacher.first_name
      this.account.last_name = teacher.last_name
      this.account.patronymic = teacher.patronymic ?? 'Отсутствует'
      this.account.email = teacher.email
      this.account.phone = teacher.phone
      this.account.region_name = teacher.region.name
      this.account.region_id = teacher.region.id
      this.account.city_name = teacher.city.name
      this.account.city_id = teacher.city.id
      this.account.locality_name = teacher.locality.name
      this.account.locality_id = teacher.locality.id
      this.account.birth_date = teacher.birth_date
      this.account.school_id = teacher.school.id
      this.account.teaching_language_id = teacher.teaching_language.id
      this.account.subject_id = teacher.subject.id
    }


  },

  watch: {
    winners2021() {
      if (this.winners2021.length > 0) {
        this.showWinners2021 = true
      } else this.showWinners2021 = false
    },

    winners2022() {
      if (this.winners2022.length > 0) {
        this.showWinners2022 = true
      } else this.showWinners2022 = false
    }
  },


  // mounted() {
  //   if (!localStorage.getItem('user')) {
  //     this.$http.get(window.API_ROOT + '/api/user/edit')
  //         .then((res) => {
  //           localStorage.setItem('user', JSON.stringify(res.body.data));
  //           this.$router.go()
  //         })
  //   }
  //   let res = JSON.parse(localStorage.getItem('user'))
  //   sessionStorage.setItem('avatar-url', `${window.API_ROOT}/api/user/avatar?file=${res.avatar}`);
  //   this.avatarUrl = `${window.API_ROOT}/api/user/avatar?file=${res.avatar}`;
  //   this.account = res;
  //   if (this.account.parent == null) {
  //     this.account.parent = {
  //       last_name: '',
  //       first_name: '',
  //       patronymic: '',
  //       email: '',
  //     }
  //
  //   }
  //   console.log(this.account)
  //   this.account.result === null && (this.account.result = 0)
  //   this.regionsLoading = true;
  //   this.$http.get(window.API_ROOT + '/api/references/regions/')
  //       .then((res) => {
  //         if (res.body.data) {
  //           this.regions = res.body.data;
  //           this.regionsLoading = false;
  //         }
  //       }).catch((e) => {
  //     this.regionsLoading = false;
  //   });
  //
  //   this.getCities(this.account.region_id, false);
  //
  //
  //   this.getLocalities(this.account.city_id, false);
  //
  //
  //   this.phone = this.account.parent.phone;
  //   if (this.phone) {
  //     this.enforcePhoneFormat();
  //   }
  //   this.additionalPhone = this.account.parent.additional_phone;
  //   if (this.additionalPhone) {
  //     this.enforceAdditionalPhoneFormat();
  //   }
  //
  //
  //   if ($('body').width() <= 992) {
  //     this.visibleMain = false;
  //     this.visibleEducation = false;
  //     this.visibleParent = false;
  //     this.visibleBtns = true;
  //   } else {
  //     this.visibleMain = true;
  //     this.visibleEducation = true;
  //     this.visibleParent = true;
  //     this.visibleBtns = false;
  //   }
  //   if (this.account.informed === 0) {
  //     this.$bvModal.show('announcement-modal')
  //   }
  //   if ($('body').width() <= 992 && this.visibleMain === true) {
  //     this.visibleBtns = false;
  //   }
  //
  //   if (sessionStorage.getItem('avatar-url')) {
  //     this.avatarUrl = sessionStorage.getItem('avatar-url');
  //   }
  //
  // },

}
</script>

<style lang="css" scoped>
.winner-nav .nav-link.active, .winner-nav .nav-item.show .nav-link {
  cursor: unset !important;
}

.btn-active {
  background-color: #1C5FC2 !important;
}
</style>

<style lang="scss">

.table_winners_name {
  font-style: normal;
  font-weight: 700;
  font-size: 30px;
  line-height: 54px;
  font-feature-settings: 'liga' off;
  color: #303030;
  width: 100%;
}

.table_winners_row {
  font-style: normal;
  font-weight: 400;
  font-size: 14px;
  line-height: 16px;
  align-items: center;
  color: #303030;
}

.table_winners_label {
  font-style: normal;
  font-weight: 700;
  font-size: 14px;
  line-height: 16px;
  align-items: center;
  color: #303030;
}

.table_winners_btn {
  border: 1px solid #FAD059;
  border-radius: 15px;
  padding: 4px 8px;
  text-decoration: none;
  color: #A16600;
  background: #FAD059;
  white-space: nowrap;

  &:hover {
    color: #A16600;
    background: #FAD059;
    text-decoration: none;
  }
}

.table_winners_title {
  margin-top: 20px;
}

.table_winners_response {
  width: 100%;
  padding-left: 0 !important;
  padding-right: 0 !important;
}

.table_winners_block {
  width: 100% !important;
  max-width: none !important;
}

.table_winners {
  width: 80% !important;
}

.table-response-helper {
  overflow-x: scroll;
}

form .avatar-uploader .btn-wrap {
  border: 1px dashed #E0E0E0;
  box-sizing: border-box;
  border-radius: 4px;
  padding: 10px;
  overflow: hidden;
  white-space: nowrap;
}

form .avatar-uploader .btn-light {
  background: #fff;
  padding: 10px 15px;
  box-shadow: 0 2px 6px #ccc;
  line-height: 22px;
}

form .avatar-uploader .btn-light svg {
  float: left;
  margin-right: 8px;
}

.avatar-uploader-icon {
  font-size: 28px;
  color: #8c939d;
  width: 178px;
  height: 178px;
  line-height: 178px;
  text-align: center;
}

.profile-content .avatar {
  float: left;
  width: 178px;
  height: 178px;
  border-radius: 178px;
  display: block;
  margin-right: 64px;
  object-fit: cover;
}

.more-info-btns {
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  padding: 22px;
}

.back-btn {
  background-color: #18A0FB;
  height: 55px;
  width: 58px;
  border-radius: 5px;
  position: absolute;
  top: 0px;
  left: 15px;
  border: none;
}

.more-info-btn {
  width: 100%;
  height: 70px;
  left: 0px;
  background: #FFFFFF;
  box-shadow: 0px 4px 4px rgba(0, 0, 0, 0.04), 0px 4px 16px rgba(0, 0, 0, 0.08);
  border-radius: 8px;
  font-weight: 500;
  font-size: 22px;
  line-height: 26px;
  color: #303030;
  display: flex;
  align-items: center;
  justify-content: center;
}

.webcam-photo-input {
  position: relative;
}

.webcam-photo-input video {
  object-fit: cover;
}

.webcam-photo-input canvas {
  object-fit: none;
}

.webcam-photo-input__circle {
  position: absolute;
  left: 50%;
  bottom: 15px;
  transform: translateX(-50%);
  width: 190px;
  height: 270px;
  border-radius: 50%;
  border: 7px solid #18A0FB;
}

.modal #dialog button.close {
  z-index: 2;
}

.announcement-modal {
  img {
    width: 100%;
    height: 209px;
    object-fit: contain;
    position: static !important;
    margin-bottom: 20px;
  }

  h4 {
    color: #05458C;
    font-size: 30px;
    line-height: 1.8;
    font-weight: bold;
  }

  h6 {
    color: #05458C;
    font-size: 26px;
    line-height: 1.8;
    font-weight: bold;
  }

  h5 {
    color: #05458C;
    font-size: 26px;
    line-height: 1.8;
    font-weight: bold;
  }

  span, p {
    line-height: 1.6 !important;

    a {
      word-break: break-all;
    }

    &.fw-medium {
      font-weight: 500 !important;
    }

    &.c-blue {
      color: #2F80ED;
    }

    &.c-dark-blue {
      color: #05458C;
    }

    &.fz-18 {
      font-size: 18px !important;
    }

    &.fz-16 {
      font-size: 16px !important;
    }
  }

  .card-main {
    box-shadow: 0 4px 4px rgb(0 0 0 / 4%), 0px 4px 16px rgb(0 0 0 / 8%);
    border-radius: 15px;
    border: none;
    margin: 0 auto 50px;
    padding: 20px 17px;
    background-color: #FFFFFF;
  }
}

@media (max-width: 992px) {
  .profile-content .avatar {
    margin: 0 auto 20px auto;
    float: none;
  }
  .profile-content .btn-take-photo {
    width: 100%;
  }
  .profile-content .btn-take-photo span {
    margin: 0 auto;
  }
  .avatar-uploader .el-upload {
    display: flex;
    flex-direction: column;
  }
  form .avatar-uploader .btn-wrap {
    width: fit-content;
    margin: 16px auto 84px;
  }
  .announcement-modal {
    h3 {
      font-size: 25px;
    }

    h6 {
      font-size: 16px;
    }

    p, span {
      font-size: 16px !important;
    }
  }

  .education-info h5 {
    font-size: 17px;
  }

  @media (max-width: 500px) {

    .table_winners {
      width: 100% !important;
    }
  }
}
</style>
